import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Likes from "../Likes"
import FitText from "@kennethormandy/react-fittext"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
// Material UI Core
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material UI Kit
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Card from "../Card/Card"

const style = {
  root: {
    flexGrow: 1,
  },
  imgFullWidth: {
    // width: "75%",
    height: "auto",
    marginTop: "20px",
  },
  name: {
    fontWeight: "800",
    fontSize: "30px",
    padding: "0 !important",
  },
  card: {
    textAlign: "center",
    display: "block",
    paddingLeft: "29px",
    marginTop: "12px,",
  },
  likeFormStyle: {
    marginBottom: "0px",
    marginRight: "8px",
  },
}

function PostInfo({ ...props }) {
  const [loaded, setLoaded] = useState(false)
  const isMobile = useMediaQuery("(max-width:768px)")
  const { uuid, postId, type, authorImage, authorName, fieldLike } = props

  const image =
    authorImage === "undefined" ? "defaults/default_avatar" : authorImage
  const fieldImage = { pid: image, resourceType: "image" }

  useEffect(() => {
    setTimeout(() => setLoaded(true), 50)
  })

  return (
    <div style={style.root}>
      <GridContainer
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card
          style={
            !isMobile ? style.card : { ...style.card, paddingRight: "6px" }
          }
          plain
          xs={12}
        >
          <GridContainer
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            {loaded && (
              <>
                <GridItem xs={3}>
                  <CloudinaryMediaSingle
                    image={fieldImage}
                    transformations={"q_100,f_auto,h_100,c_scale"}
                    alt={authorName}
                    styles={{
                      float: "left",
                      maxWidth: "84.25px",
                      height: "auto",
                      maxHeight: "34px",
                    }}
                  />
                </GridItem>
                <GridItem
                  style={style.name}
                  xs={6}
                  className={"cardWrapperNoPadding"}
                >
                  <FitText maxFontSize={13}>{authorName}</FitText>
                </GridItem>
                <GridItem xs={3} className={"cardWrapperNoPadding"}>
                  <Likes
                    id={postId}
                    uuid={uuid}
                    type={type}
                    field_like={fieldLike}
                    clear={!isMobile}
                    getLiveLikes={true}
                    formStyle={style.likeFormStyle}
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
        </Card>
      </GridContainer>
    </div>
  )
}

export default PostInfo

PostInfo.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fieldLike: PropTypes.object,
}
