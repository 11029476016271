import React, { Component } from "react"
import Pagination from "./pagination.js"
import { InfiniteScroll } from "./infiniteScroll.js"
import MainGrid from "./mainGrid.js"
import InitialSearch from "./initialSearch"
import { RefreshContent } from "react-js-pull-to-refresh"
import { isBrowser } from "../../utils/Auth"
// import SeaveeSplash from "./seaveeSplash"
// import RSVPRegistration from "../SeaVeeRegistration"
// import GridContainer from "../Grid/GridContainer"
// import GridItem from "../Grid/GridItem";

class PaginatedIndex extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (isBrowser) {
      console.log("*** Constructing View ***")
      if (
        this.props.globalState.isInitializing() ||
        !this.props.globalState.useInfiniteScroll
      ) {
        const {
          pageContext: { pagePosts, currentPage },
        } = this.props
        const pageKey = "page" + currentPage

        console.log(`View is initializing items according to ${pageKey}.`)
        this.props.globalState.updateState({
          [pageKey]: pagePosts,
          cursor: currentPage + 1,
        })
      }
    }
  }

  render() {
    const g = this.props.globalState
    const pageContext = this.props.pageContext
    const paginationData = {
      currentPage: pageContext.currentPage,
      countPages: pageContext.countPages,
      useInfiniteScroll: g.useInfiniteScroll,
    }

    return (
      <>
        <InitialSearch />
        {/*<GridContainer spacing={0}>*/}
        {/*  <GridItem xs={12}>*/}
        {/*    <SeaveeSplash />*/}
        {/*  </GridItem>*/}
        {/*  <GridItem xs={12}>*/}
        {/*    <RSVPRegistration />*/}
        {/*  </GridItem>*/}
        {/*</GridContainer>*/}

        <InfiniteScroll
          throttle={700}
          threshold={100}
          hasMore={g.hasMore(pageContext)}
          onLoadMore={g.loadMore}
        >
          {/* Grid given as a child element for Infinite Scroll. */}
          <MainGrid globalState={g} pageContext={pageContext} />
        </InfiniteScroll>

        {g.useInfiniteScroll && g.cursor !== 0 && !g.hasMore(pageContext) && (
          <div style={{ paddingTop: "40px" }}>
            <h4>
              <p>
                Congrats! You scrolled through all items starting from page
                {" " + pageContext.currentPage}.
              </p>
            </h4>
          </div>
        )}

        {(g.cursor === 0 || g.hasMore(pageContext)) && <RefreshContent />}

        {/* Fallback to Pagination for non JS users. */}
        {g.useInfiniteScroll && (
          <noscript>
            <style>{`.spinner { display: none !important; }`}</style>
            <Pagination paginationData={paginationData} />
            <h4>
              <p>Infinite Scroll does not work without JavaScript.</p>
            </h4>
          </noscript>
        )}

        {/* Fallback to Pagination on toggle (for demo) and also on error. */}
        {!g.useInfiniteScroll && <Pagination paginationData={paginationData} />}
      </>
    )
  }
}

export default PaginatedIndex
