import React, { useState } from "react"
import CustomInput from "../CustomInput/CustomInput"
import Button from "../CustomButtons/Button"
import { navigate } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import Parallax from "../Parallax/Parallax"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import landingPageStyle from "../../assets/jss/material-kit-pro-react/views/landingPageStyle"
import withStyles from "@material-ui/core/styles/withStyles"

const InitialSearch = (props) => {
  const { classes, ...rest } = props
  const [term, setTerm] = useState("")
  const isMobile = useMediaQuery("(max-width:768px)")
  const Heading = isMobile ? "h2" : "h1"
  const SubHeading = isMobile ? "p" : "h4"

  const style = {
    parallax: {
      margin: "-72px -4px 0",
    },
    arrow: {
      color: "white",
      position: "absolute",
      left: "50%",
      bottom: "20px",
      transform: "translateX(-50%)",
      textAlign: "center",
    },
    button: {
      float: "right",
    },
  }

  const findListings = () => {
    const qs =
      term.trim() === "" ? "" : `?query=${term.trim().replace(" ", "+")}`
    navigate(`/listings${qs}`, { replace: true })
  }

  const randomPlaceholder = () => {
    const list = [
      "Sea Hunt Ultra 239SE",
      "Sea Hunt Gamefish 30",
      "Sea Hunt BX 25 FS",
      "Sea Hunt Triton 225",
      // "Blackwater 43' SportFish Edition",
      // "Blackwater 39' SportFish",
      // "Blackwater 36' SportFish",
      // "Deep Impact 499 Sport",
      // "Deep Impact 399 Sport",
      // "Deep Impact 399 Cabin",
      "Sea Fox 368 Commander",
      "Sea Fox 328 Commander",
      "Sea Fox 288 Commander",
      "Invincible 35' Catamaran",
      "Invincible 42' Open Fisherman",
      "Invincible 33' Open Fisherman",
      // "Vandalize V305",
      // "Vandalize V305 SUV",
      "Mag Bay 33CC",
      "Mag Bay 42",
      "Release Boatworks 34'",
      "Release Boatworks 43'",
      "Insetta I45C",
      "Insetta 35IFC",
      // "Scout 420LXF",
      // "Scout 355LXF",
      "Sea Cat 260",
      "Blackfin 332CC",
      "Blackfin 212CC",
      "PowerPlay 30 Rum Runner Edition",
      "PowerPlay 42 Sport Yacht",
      // "Streamline 26TE",
      // "Streamline 45TE",
      "Reef Runner 230 Open",
      "Reef Runner 230 CC",
      "Reef Runner 340",
      "Concept 392 ProFish",
      "Sea Lion 34' Center Console",
      "Tideline 365 Offshore",
    ]
    return list[Math.floor(Math.random() * list.length)]
  }

  const Arrow = () => {
    return (
      <div style={style.arrow}>
        <img
          src={require("../../assets/img/arrow_white.gif")}
          width="100px"
          alt=""
        />
        <p>
          Want the latest and greatest!?
          <br /> Scroll down to see the latest listings, community posts, feed
          and other great content!
        </p>
      </div>
    )
  }

  return (
    <div style={style.parallax}>
      <Parallax intro={true} filter="dark" isMobile={isMobile}>
        <div className={classes.container}>
          <GridContainer
            alignItems="center"
            alignContent="center"
            justify="center"
            spacing={0}
          >
            <GridItem xs={12} sm={6} md={6}>
              <Heading className={classes.title}>
                Center Consoles: Easily Find, Finance & Enjoy.
              </Heading>
              <SubHeading>
                Search our great selection of listings below and get the best
                possible cost of ownership right away. Contact us right from
                there and we'll set you up with the best possible financing to
                make it happen!
              </SubHeading>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  findListings()
                }}
              >

                <CustomInput
                  // labelText={"Search"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    color: "primary",
                    value: term,
                    onChange: (event) => setTerm(event.target.value),
                    type: "text",
                    className: "form-control",
                    name: "search",
                    placeholder: `E.g. ${randomPlaceholder()}`,
                    autoFocus: false,
                  }}
                  attributes={{
                    'aria-label': "search",
                    style: {color: "white"}
                  }}
                />
                <Button style={style.button} type="submit" color="primary">
                  Search Now!
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </div>
  )
}

export default withStyles(landingPageStyle)(InitialSearch)
