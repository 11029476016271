import React from "react"
import LazyLoad from "react-lazyload"
import { Grid } from "@material-ui/core"
import GridItem from "./gridItem.js"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import './lazyload-fix.css'

const isBrowser = typeof window !== "undefined"

const MainGrid = (props) => {
  const isMobile = useMediaQuery("(max-width:768px)")
  const g = props.globalState
  const currentPage = props.pageContext.currentPage
  const items = []
  let i = 0,
    j = 0,
    numberOfItemsOnLatestPage = 0
  const js = !g.isInitializing()
  if (g.useInfiniteScroll && g["page" + currentPage]) {
    for (let pageNum = currentPage; ; pageNum++) {
      const key = "page" + pageNum
      if (g[key]) {
        /* Add gridItems that we have received metadata for. */
        numberOfItemsOnLatestPage = g[key].length
        for (j = 0; j < numberOfItemsOnLatestPage; j++) {
          items.push(
            <LazyLoad key={"gi" + i++} offset={50} {...props}>
              <GridItem
                js={js}
                item={g[key][j]}
                key={"gi" + i++}
                isMobile={isMobile}
              />
            </LazyLoad>
          )
        }
      } else {
        const lastFetchedPage = Math.min(g.cursor, props.pageContext.countPages)
        for (; pageNum <= lastFetchedPage; pageNum++) {
          /* For each page that we have fetched, but haven't received metadata for, render empty gridItems. */
          for (j = 0; j < numberOfItemsOnLatestPage; j++) {
            items.push(
              <LazyLoad
                key={"gi" + i++}
                offset={50}
                {...props}
              >
                <GridItem key={"gi" + i++} isMobile={isMobile} />
              </LazyLoad>
            )
          }
        }
        /* Don't add anything for pages that the user hasn't fetched (scrolled to) yet. */
        break
      }
    }
  } else {
    /* This 'else' covers special cases when we don't have items in global state.
     * - If a user has JS disabled (we won't be able to manipulate global state).
     * - And the very first render on initial pageload.
     * In these cases we simply render the items of this page (corresponds to a path like "/", "/2", "/3",...)
     */
    props.pageContext.pagePosts.forEach((item) =>
      items.push(
        <LazyLoad
          key={"gi" + i++}
          offset={50}
          {...props}
        >
          <GridItem item={item} key={"gi" + i++} isMobile={isMobile} />
        </LazyLoad>
      )
    )
  }

  isBrowser && console.log("Rendering " + i + " gridItems.")

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems={isMobile ? "flex-start" : "center"}
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      {items}
    </Grid>
  )
}

export default MainGrid
