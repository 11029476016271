import React from "react"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import { GlobalStateContext } from "../components/globalState.js"
import PaginatedIndex from "../components/PaginatedIndex"

class PaginatedIndexTemplate extends React.Component {
  render() {
    return (
      <Layout disableGutters={true} maxWidth={"xl"} headerColor={"transparent"}>
        <GlobalStateContext.Consumer>
          {globalState => (
            <>
              <SEO title="CCO Home" keywords={[`Center Consoles Only`, `CCO`, `Boats`]}/>
              <PaginatedIndex
                globalState={globalState}
                pageContext={this.props.pageContext}
              />
            </>
          )}
        </GlobalStateContext.Consumer>
      </Layout>
    )
  }
}

export default PaginatedIndexTemplate
