import React from "react"

const throttle = require("lodash.throttle")
import { Container } from "@material-ui/core"

export class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      boundingClientRect: 0,
    }
    this.scrollHandler = this.scrollHandler.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
  }

  scrollHandler = () => {}

  resizeHandler = () => {}

  componentDidMount() {
    this.scrollHandler = throttle(this.checkWindowScroll, this.props.throttle)
    this.resizeHandler = throttle(this.checkWindowScroll, this.props.throttle)

    window.addEventListener("scroll", this.scrollHandler, { passive: true })
    window.addEventListener("resize", this.resizeHandler, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler)
    window.removeEventListener("resize", this.resizeHandler)
  }

  componentDidUpdate(prevState) {
    if (this.state.boundingClientRect !== prevState.boundingClientRect) {
      this.scrollHandler()
    }
  }

  checkWindowScroll = () => {
    // console.log("Window height " + window.innerHeight + " Sentinel boundingRectTop " + this.sentinel.getBoundingClientRect().top)
    if (
      this.props.hasMore &&
      this.sentinel &&
      this.sentinel.getBoundingClientRect().top - window.innerHeight <
        this.props.threshold
    ) {
      // console.log("Window height " + window.innerHeight + " Sentinel boundingRectTop " + this.sentinel.getBoundingClientRect().top)
      this.setState(
        {
          boundingClientRect: this.sentinel.getBoundingClientRect().top,
        },
        this.props.onLoadMore()
      )
    }
  }

  render() {
    const sentinel = <div ref={i => (this.sentinel = i)} />

    if (this.props.render) {
      return this.props.render({
        sentinel,
        children: this.props.children,
      })
    }

    if (this.props.component) {
      // const Container = this.props.component;
      return <Container sentinel={sentinel}>{this.props.children}</Container>
    }

    return (
      <div>
        {this.props.children}
        {sentinel}
      </div>
    )
  }
}
