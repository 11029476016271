import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import TimeAgo from "react-timeago"
import { normalizeNodeType, pluralize } from "../../helpers/common"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import PostInfo from "../PostInfo"
// Material Core and Kit
import { Grid } from "@material-ui/core"
import Card from "../Card/Card"
import CardBody from "../Card/CardBody"
import Button from "../CustomButtons/Button"
import imagesStyles from "../../assets/jss/material-kit-pro-react/imagesStyles"

const style = {
  ...imagesStyles,
  floatRight: {
    float: "right",
    color: "#555555",
  },
  readMoreButton: {
    width: "100%",
    color: "#555555",
  },
  fullWidth: {
    width: "100%",
    height: "auto",
  },
  cardDesktop: {
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    width: "480px",
    maxWidth: "100%",
  },
  cardMobile: {
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.10), 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
    width: "480px",
    maxWidth: "99.5%",
    display: "inline-block",
    marginLeft: "1px",
  },
  ribbonWrapper: {
    width: "90px",
    height: "92px",
    overflow: "hidden",
    position: "absolute",
    top: "-3px",
    left: "-3px",
  },
  ribbon: {
    font: "bold 15px sans-serif",
    color: "#fff",
    textAlign: "center",
    WebkitTransform: "rotate(-45deg)",
    MozTransform: "rotate(-45deg)",
    OTransform: "rotate(-45deg)",
    position: "relative",
    padding: "8px 0",
    top: "16px",
    left: "-28px",
    width: "120px",
    backgroundColor: "#00acc1",
  },
}

function GridItem(props) {
  const { isMobile } = props
  return (
    props.item !== undefined && (
      <React.Fragment>
        <Grid key={props.index} item xs={12}>
          <Card style={isMobile ? style.cardMobile : style.cardDesktop}>
            <>
              {props.item.type === "node__listing" && (
                <div style={style.ribbonWrapper}>
                  <div style={style.ribbon}>
                    {props.item.title.indexOf("Rental") !== -1
                      ? "For Rent"
                      : "For Sale!"}
                  </div>
                </div>
              )}
              <Link style={style.cleanText} to={props.item.alias}>
                <CloudinaryMediaSingle
                  image={props.item.image}
                  transformations={"q_auto,f_auto,dpr_2.0,w_480,c_scale"}
                  alt={props.item.alias}
                  styles={{ width: "100%", height: "auto" }}
                  progressive={"w_480,c_scale"}
                />
              </Link>
              <PostInfo
                postId={props.item.drupalInternalNid}
                uuid={props.item.drupalId}
                type={props.item.type}
                created={props.item.created}
                authorName={props.item.relationships.uid.name}
                authorImage={props.item.avatar}
                fieldLike={props.item.fieldLike}
              />
            </>
            <CardBody>
              <small>
                <em style={{ color: "#555555" }}>
                  {normalizeNodeType(props.item.type)}
                </em>
                <em style={style.floatRight}>
                  <TimeAgo date={props.item.created} />
                </em>
              </small>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.item.summary,
                  }}
                />
              </div>
              <Link style={style.cleanText} to={props.item.alias}>
                <Button color={"white"} style={style.readMoreButton}>
                  {props.item.image.resourceType === "image"
                    ? props.item.fieldImage.length > 1
                      ? `See ${props.item.fieldImage.length - 1} More ` +
                        pluralize("Item", props.item.fieldImage.length - 1, "s")
                      : "Read More"
                    : props.item.fieldImage.length > 1
                    ? `See Full Video & ${
                        props.item.fieldImage.length - 1
                      } More ` +
                      pluralize("Item", props.item.fieldImage.length - 1, "s")
                    : "See Full Video"}
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Grid>
      </React.Fragment>
    )
  )
}

export default GridItem
