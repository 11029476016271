import React, { Component } from "react"
import PropTypes from "prop-types"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import {
  getUid,
  isBrowser,
  isAuthenticated,
  getAuthHeadersApi,
  getAuthHeadersJsonapi,
} from "../../utils/Auth"
import axios from "axios"
import Shaka from "./Shaka"
// Material UI Core/Styles
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import withStyles from "@material-ui/core/styles/withStyles"
import likesStyle from "./jss/likesStyle.jsx"

const baseUrl = `${process.env.GATSBY_API_URL}`

class Likes extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      likes: 0,
      liked: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { uuid, type, field_like, classes, getLiveLikes } = this.props

    if (getLiveLikes) {
      this.getLikes(uuid, type)
    } else {
      if (this._isMounted && field_like !== null) {
        this.setState({
          likes: this.calcLikes(field_like.clicked_by),
          liked: field_like.clicked_by
            ? field_like.clicked_by.indexOf(getUid()) > -1
            : false,
        })
      }
    }

    if (this._isMounted && classes) {
      this.setState({
        styles: {
          shaka: classes.footerButtons,
          tooltip: classes.tooltip,
        },
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  calcLikes = string => {
    if (string) {
      let arr = string
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/"/g, "")
        .split(",")
      const count = arr.length
      if (count > 1) {
        return count - 1
      }
    }
    return 0
  }

  getLikes = (id, type) => {
    if (!isBrowser) {
      return
    }
    let self = this

    const params = `/jsonapi/${type.replace("__", "/")}/${id}`
    axios
      .get(baseUrl + params)
      .then(function(response) {
        // console.log(response.data.data.attributes.field_like)
        const likes = response.data.data.attributes.field_like
        if (likes !== null) {
          if (self._isMounted) {
            self.setState({
              likes: self.calcLikes(likes.clicked_by),
              liked: likes.clicked_by
                ? likes.clicked_by.indexOf(getUid()) > -1
                : false,
            })
          }
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  postLike = (drupal_id, type) => {
    if (!isBrowser) {
      return
    }

    let self = this
    const params = "/likeapi/post?_format=json"
    const payload = {
      data: {
        entity_type: type.includes("node") ? "node" : type,
        entity_id: drupal_id,
      },
    }
    axios
      .post(baseUrl + params, payload, getAuthHeadersApi())
      .then(function(response) {
        // console.log(response);
        if (self._isMounted) {
          self.setState({
            likes: self.calcLikes(response.data.clicked_by),
            liked: response.data.clicked_by.indexOf(getUid()) > -1,
          })
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  render() {
    const { type, id, classes, clear, formStyle = [] } = this.props
    const { liked, likes } = this.state
    return (
      <div id={`Likes-${id}`}>
        {isAuthenticated() ? (
          <form
            onSubmit={e => {
              e.preventDefault()
              this.postLike(id, type)
            }}
            style={formStyle}
          >
            <Button
              type="submit"
              color="primary"
              className={!clear ? classes.floatRight : classes.clearAll}
            >
              <Shaka shaka={liked} />
              {this.state.likes}
            </Button>
          </form>
        ) : (
          <Link
            to={"/login"}
            state={{ redirect: globalHistory.location.pathname }}
          >
            <Tooltip
              title="Please log in to add your approval"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="primary"
                className={!clear ? classes.floatRight : classes.clearAll}
                style={formStyle}
              >
                <Shaka shaka={false} />
                {likes}
              </Button>
            </Tooltip>
          </Link>
        )}
      </div>
    )
  }
}

export default withStyles(likesStyle)(Likes)

Likes.propTypes = {
  id: PropTypes.number,
  uuid: PropTypes.string,
  type: PropTypes.string.isRequired,
  field_like: PropTypes.object,
  styles: PropTypes.object,
  // getLiveLikes: PropTypes,
  drupalId: PropTypes.string,
}
